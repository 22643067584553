.App {
	text-align: center;
	background-color: #191a26;
	min-height: 100vh;
	width: 100vw;
	margin: 0;
	padding: 0;
}

.centered {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
}

/* SELECTORS */
.selectorContainer {
	width: 100%;
	box-sizing: border-box;
}

.selector {
	background-color: rgba(255, 255, 255, 0.1);
	margin: 1rem;
	font-family: "Inter", sans-serif;
	border-radius: 0.75rem;
	padding: 2rem 0;
	box-sizing: border-box;
	width: 95%;
	margin-left: auto;
	margin-right: auto;

	transition: all 0.5s ease;
}

.selector:hover {
	transform: scale(1.02);
}

.selectorText {
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-weight: 700;
	font-size: 2rem;
}

.dashboardTogglerOn,
.dashboardTogglerOff {
	background-color: rgba(12, 144, 27, 0.1);
	margin: 1rem;
	font-family: "Inter", sans-serif;
	padding: 2rem 0;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	border-radius: 0.75rem;

	transition: all 0.5s ease;
}

.dashboardTogglerOff {
	background-color: rgba(205, 26, 26, 0.1);
}

.dashboardTogglerOn:hover,
.dashboardTogglerOff:hover {
	transform: scale(1.02);
	cursor: pointer;
}

/* DASHBOARD SECTIONS */
.dashboardScreenContainer,
.viewScreenContainer {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
}

.dashboardSection {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem 4rem;
}

.dashboardHeader {
	display: flex;
	flex-direction: column;
	background-color: rgba(46, 52, 73, 0.7);
	padding: 1.25rem 1.5rem 1.25rem 1.5rem;
	border-radius: 0.75rem;
	width: 100%;
	box-sizing: border-box;
}

.dashboardHeaderLine {
	width: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	height: 1.5px;
	margin: 0.6rem 0;
}

.dashboardTitle {
	text-align: start;
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-weight: 700;
	font-size: 3.25rem;
}

.dashboardHeaderInfoContainer {
	display: flex;
	justify-content: flex-start;
	transition: all 0.25s ease;
}

.fade-in {
	opacity: 1;
}

.fade-out {
	opacity: 0;
}

.dashboardHeaderInfo {
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.75rem;
	margin-right: 1.5rem;
	font-weight: 300;
}

.dashboardBody {
	background-color: rgba(46, 52, 73, 0.7);
	width: 100%;
	/* padding: 1.25rem 1.5rem 1.25rem 1.5rem; */
	border-radius: 0.75rem;
	margin-top: 1.75rem;
	padding: 1rem 0;
	height: 50vh;
}

.dashboardBodyTitleContainer {
	display: flex;
	justify-content: flex-start;
	padding: 0 1.5rem;
}

.dashboardBodyTitle {
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.5rem;
	font-weight: 700;
	text-align: start;
}

.dashboardBodyLine {
	width: 100%;
	box-sizing: border-box;
	background-color: rgba(255, 255, 255, 0.5);
	background-clip: content-box;
	height: 1.5px;
	margin: 0.6rem 0;
	padding: 0 1.5rem;
}

.lapTimeContainer {
	width: 100%;
	overflow: hidden;
	transition: all 0.25s ease-in-out;
}

.lapTime {
	display: flex;
	justify-content: flex-start;
	padding: 0.4rem 1.5rem;
}

.lapTimeLight {
	display: flex;
	justify-content: flex-start;
	padding: 0.4rem 1.5rem;
	background-color: rgba(255, 255, 255, 0.1);
}

.record {
	background-color: rgba(246, 66, 255, 0.5);
}

.lapTimeElement {
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.5rem;
	font-weight: 400;
	text-align: start;
}

.dashboardReturnButton {
	font-family: "Inter", sans-serif;
	background-color: rgba(255, 255, 255, 0.1);
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
	padding: 0.5rem 1.2rem;
	transition: all 0.3s ease;
	border-radius: 0.4rem;
	position: absolute;
	top: 10px;
	left: 10px;
	opacity: 0;
}

.dashboardReturnButton:hover {
	cursor: pointer;
	opacity: 1;
}

.dashboardShortCycleTimeButton {
	all: unset;
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
	padding: 0.5rem 1.2rem;
	transition: all 0.3s ease;
	border-radius: 0.4rem;
	position: absolute;
	top: 10px;
	right: 10px;
	opacity: 0;
}

.dashboardShortCycleTimeButton:hover {
	opacity: 1;
	cursor: pointer;
}

.dashboardShortCycleTimeButton.green {
	background-color: rgba(12, 144, 27, 0.1);
}

.dashboardShortCycleTimeButton.red {
	background-color: rgba(205, 26, 26, 0.1);
}

/* ADD SCREEN */
.addScreenContainer {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	box-sizing: border-box;
}

.addScreenButtonContainer {
	background-color: rgba(46, 52, 73, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	border-radius: 0.75rem;
	width: 100%;
	box-sizing: border-box;
}

.addScreenButton,
.addScreenButtonSelected {
	all: unset;
	font-family: "Inter", sans-serif;
	background-color: rgba(255, 255, 255, 0.1);
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
	padding: 0.5rem 0.75rem;
	transition: all 0.3s ease;
	border-radius: 0.4rem;
	margin: 0 0.25rem;
}

.addScreenButton:hover {
	transform: scale(1.04);
	cursor: pointer;
}

.addScreenButtonSelected {
	font-family: "Inter", sans-serif;
	background-color: rgba(255, 255, 255, 0.2);
	color: rgba(255, 255, 255, 0.75);
	border: 1px solid white;
	transform: scale(1.01);
}

.addScreenButtonSelected:hover {
	cursor: pointer;
}

.addScreenFormContainer {
	background-color: rgba(46, 52, 73, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 1.5rem;
	border-radius: 0.75rem;
	width: 100%;
	margin-top: 1rem;
}

.addScreenForm {
	width: 100%;
}

.addScreenFormLabel {
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.1rem;
	font-weight: 600;
	text-align: start;
	width: 100%;
	display: block;
}

.addScreenFormInput {
	all: unset;
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-size: 0.9rem;
	padding: 0.4rem;
	margin-top: 0.35rem;
	margin-bottom: 1rem;
	font-weight: 400;
	text-align: start;
	width: 100%;
	display: block;
	background-color: #222733;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	transition: all 0.3s ease;
}

.addScreenFormInput:focus {
	border-bottom: 1px solid rgba(255, 255, 255, 07);
}

.timeInputGroup {
	display: flex;
	/* width: 100%; */
	margin-top: 0.35rem;
	margin-bottom: 1rem;
}

.addScreenTimeInput {
	all: unset;
	padding: 0.4rem;
	width: 33%;
	font-family: "Inter", sans-serif;
	color: rgba(255, 255, 255, 0.75);
	font-size: 0.9rem;
	font-weight: 400;
	text-align: start;
	background-color: #222733;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	transition: all 0.3s ease;
}

.addScreenFormSubmitButton {
	all: unset;
	font-family: "Inter", sans-serif;
	background-color: rgba(255, 255, 255, 0.1);
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.3rem;
	font-weight: 600;
	box-sizing: border-box;
	text-align: center;
	padding: 0.5rem 1.2rem;
	transition: all 0.3s ease;
	border-radius: 0.4rem;
	width: 100%;
}

.addScreenFormSubmitButton:hover {
	transform: scale(1.01);
	cursor: pointer;
}

.addScreenReturnButtonContainer {
	background-color: rgba(46, 52, 73, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	border-radius: 0.75rem;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 1rem;
}

.addScreenReturnButton {
	all: unset;
	font-family: "Inter", sans-serif;
	background-color: rgba(255, 255, 255, 0.1);
	color: rgba(255, 255, 255, 0.75);
	font-size: 1.3rem;
	font-weight: 600;
	box-sizing: border-box;
	text-align: center;
	padding: 0.5rem 1.2rem;
	transition: all 0.3s ease;
	border-radius: 0.4rem;
	width: 100%;
}

@media only screen and (min-width: 768px) {
	.selector,
	.dashboardTogglerOn,
	.dashboardTogglerOff {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (min-width: 1440px) and (max-width: 1800px) {
    .dashboardSection {
        padding: 1.5rem;
    }

	/* Font Sizes */

	.dashboardTitle {
		font-size: 2rem;
	}

	.dashboardHeaderInfo {
		font-size: 1.3rem;
		margin-right: 0.75rem;
	}

	.dashboardBodyTitle {
		font-size: 1.2rem;
	}

	.lapTimeElement {
		font-size: 1.2rem;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1439px) {
	.dashboardSection {
		padding: 0.75rem;
	}

	.dashboardHeaderInfo {
		margin: 0;
	}

	/* Font Sizes */

	.dashboardTitle {
		font-size: 1.5rem;
	}

	.dashboardHeaderInfo {
		font-size: 1rem;
		margin-right: 0.5rem;
	}

	.dashboardBodyTitle {
		font-size: 0.8rem;
	}

	.lapTimeElement {
		font-size: 0.8rem;
	}
}

@media only screen and (max-width: 767px) {
	.dashboardScreenContainer,
	.viewScreenContainer {
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		/* padding-top: 3.25rem; Add padding to make space for the absolute return button on mobile */
	}

	.addScreenContainer {
		padding: 0 1rem;
	}

	.selector,
	.dashboardTogglerOn,
	.dashboardTogglerOff {
		width: 85%;
		margin-left: auto;
		margin-right: auto;
	}

	.dashboardSection {
		padding: 0.75rem;
	}

	.dashboardHeaderInfoContainer {
		display: flex;
		flex-direction: column;
		transition: all 0.25s ease;
	}

	.dashboardBodyLine {
		margin: 0.3rem 0;
	}

	.lapTime {
		padding: 0.2rem 1.5rem;
	}

	.dashboardHeader {
		padding: 0.75rem 1rem;
	}

	.dashboardHeaderInfo {
		margin-right: 0;
		margin-bottom: 0.25rem;
	}

	.dashboardReturnButton {
		opacity: 1;
		position: relative;
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		top: 0;
		left: 0;
		box-sizing: border-box;
		margin-top: 1rem;
		margin-bottom: 0.25rem;
	}

	/* Font Sizes */
	.selectorText {
		font-size: 1.5rem;
	}

	.dashboardTitle {
		font-size: 1.5rem;
		text-align: center;
	}

	.dashboardHeaderInfo {
		font-size: 1rem;
	}

	.dashboardBodyTitle {
		font-size: 0.75rem;
	}

	.lapTimeElement {
		font-size: 0.5rem;
	}

	.dashboardShortCycleTimeButton {
		display: none;
	}

	.dashboardShortCycleTimeButton:hover {
		display: none;
	}
}
